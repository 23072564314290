import styled from "styled-components";

import MagicLink from "../MagicLink";
import ProductCtaBar from "../product/ProductCtaBar";
import Text from "../Text";

import metrics from "../../utils/metrics";
import { Color, Font, rem } from "../../utils/style";
import { getCadencePlans, cadencePlanDetails } from "../../utils/ritualData";

// Services
import intl from "../../services/intl";

// Utils
import {
  getContentfulProductForPlan,
  getPlanForId,
  getPlanIdForProductSku,
  getProductAttributes,
} from "../../utils/planToProduct";

const ProductCtaBarWrapper = styled.div`
  [data-whatintent="keyboard"] & * {
    display: none;
  }
`;

const ProductLink = styled(MagicLink)`
  ${Font.circular};
  font-size: ${rem(14)} !important;
  line-height: ${rem(24)} !important;
  border-bottom: 2px solid ${Color.ritualBlue};
`;

const StickyProductNav = (props) => {
  const {
    ctaLocation,
    scrollOffset,
    showLearnMore,
    showTopBar,
    quantity,
    sku,
    selectedPlanId = null,
    ctfProduct = null,
    cadencePlanCta = false,
  } = props;

  function trackProductCTAClick(location, e) {
    let event = {
      title: e.target.innerText,
      location: location,
    };
    metrics.track("CTA Clicked", event);
  }

  if (!sku) return null;

  const planId = selectedPlanId || getPlanIdForProductSku(sku);

  if (!planId) return null;

  const contentfulProduct = ctfProduct
    ? ctfProduct
    : getContentfulProductForPlan(planId);

  if (!contentfulProduct) return null;

  const productAttributes = getProductAttributes(planId, null, sku);
  const { price, urlSlug, currency } = productAttributes;

  let ctaText = intl.t("general.button-add-cart", "Add to Cart");

  // Delivery cadence CTA "Add Monthly" or "Add Every 3 Months"
  if (cadencePlanCta) {
    const cadencePlans = getCadencePlans(
      ctfProduct.ritualProduct.ritualPlans,
      {
        type: currency,
      },
      true,
    );

    const selectedCadencePlan = cadencePlans.find(
      (plan) => plan.plan_id === selectedPlanId,
    );
    if (!selectedCadencePlan) return null;

    const selectedCadencePlanDetails = cadencePlanDetails(selectedCadencePlan);
    let cadenceText = selectedCadencePlanDetails.selectorHeader;
    cadenceText = cadenceText.charAt(0).toLowerCase() + cadenceText.slice(1);

    ctaText = `Add ${cadenceText}`;
  }

  return (
    <ProductCtaBarWrapper>
      <ProductCtaBar
        onClick={trackProductCTAClick.bind(this, ctaLocation)}
        product={contentfulProduct}
        quantity={quantity}
        mainText={intl.t("product.hero.button-price", "{amount} per month", {
          amount: intl.formatCurrency(price, {
            round: true,
          }),
        })}
        buttonCta={ctaText}
        productName={contentfulProduct && contentfulProduct.name}
        hasActiveLink={showLearnMore}
        showTopBar={showTopBar}
        scrollOffset={scrollOffset}
        planType={productAttributes.billing_type}
        selectedPlan={getPlanForId(planId)}
        {...props}
      >
        {showLearnMore && (
          <ProductLink to={`/products/${urlSlug}`} className="-underline">
            <Text id="general.learn-more" defaultMessage="Learn More" />
          </ProductLink>
        )}
      </ProductCtaBar>
    </ProductCtaBarWrapper>
  );
};

export default StickyProductNav;

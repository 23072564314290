import styled from "styled-components";

// Utils
import NameMap from "../../utils/nameMap";
import { Color, Font, media, rem } from "../../utils/style";

// Components
import StickyNav from "../global/StickyNav";
import MagicLink from "../MagicLink";
import ProductButton from "./ProductButton";

const ProductName = styled.h2.attrs({
  id: "product-cta-bar-desktop_container_column_product-name",
})`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(22)};
  line-height: ${rem(32)};
  font-weight: 500;
  text-align: left;
  letter-spacing: -0.2px;
  margin: 0;

  em {
    ${Font.dutch}
    font-style: italic;
  }

  ${media.tablet`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
    letter-spacing: 0;
  `}

  ${media.mobile`
    display: none !important;
  `}
`;

const YieldedLinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;

  &.active {
    display: none;
  }

  & > * {
    margin: 0;
  }
`;

const ProductLinks = styled.div.attrs({
  id: "product-cta-bar-desktop_container_column_links",
})`
  display: flex;
`;

const ProductTitle = styled.h1`
  ${Font.circular}
  background-color: ${Color.white};
  font-size: ${rem(18)};
  display: flex;
  margin: 0;

  > p {
    margin: 0;
  }

  em {
    ${Font.dutch}
    font-style: italic;
  }
`;

const Column = styled.div`
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.tablet`
    width: 100%;
  `};
`;

const MobileColumn = styled.div`
  ${Font.circular}
  font-size: ${rem(18)};

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export default function ProductCtaBar(props) {
  const {
    productName,
    onClick,
    children,
    product,
    quantity,
    planType,
    selectedPlan,
    buttonCta = null,
    floatText = false,
    cadencePlanCta = false,
    showAnimated = false,
    isVisible = false,
  } = props;

  const productNameHtml = NameMap(productName).html;
  let productButtonProps = {
    selectedPlan,
    planType,
    product,
    quantity,
    prefix: buttonCta,
    trackCTAClick: onClick,
    className: "maxwidth",
    productButtonProps: {
      showDash: false,
      floatText: floatText,
      cadencePlanCta,
      showAnimated: showAnimated,
      isVisible: isVisible
    },
  };

  let className = "";
  if (productButtonProps.productButtonProps.floatText) {
    className = "col-sm-6 justify-content-end";
  }

  const linkNode = children ? children.props.to : null;
  return (
    <StickyNav
      {...props}
      topNav={
        <>
          <Column className="d-none d-sm-flex col-12">
            <ProductName
              dangerouslySetInnerHTML={{ __html: productNameHtml }}
            />
            <ProductLinks className={className}>
              {children && (
                <YieldedLinkContainer>{children}</YieldedLinkContainer>
              )}
              <ProductButton
                {...productButtonProps}
                location="Product CTA Bar Top"
                className="d-flex justify-content-end"
              />
            </ProductLinks>
          </Column>
          <MobileColumn className="d-flex d-sm-none col-12">
            {linkNode ? (
              <MagicLink to={linkNode}>
                <MobileProductTitle name={productNameHtml} />
              </MagicLink>
            ) : (
              <MobileProductTitle name={productNameHtml} />
            )}
            {children}
          </MobileColumn>
        </>
      }
      bottomNav={
        <ProductButton
          {...productButtonProps}
          location="Product CTA Bar Bottom"
        />
      }
    />
  );
}

function MobileProductTitle(props) {
  return <ProductTitle dangerouslySetInnerHTML={{ __html: props.name }} />;
}
